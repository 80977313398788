<template>
  <section v-if="addOrderModal">
    <div v-if="successServer" class="success__server">
      <div>
        <h1>Заказ {{ orderId }} успешно создан!</h1>
        <p>Менеджер обязательно с Вами свяжется в ближайшее время</p>
        <form-button
          title="ПРОДОЛЖИТЬ"
          class="button__yellow"
          type="button"
          @click="returnHandler"
        ></form-button>
      </div>
    </div>
    <div v-else class="mobile order__mobile">
      <div @click="closeOrder" class="order__mobile--close">
        <div>
          <img src="../../assets/images/close.svg" alt="Закрыть" />
        </div>
      </div>
      <div class="order__mobile--header">
        <h1 v-if="newOrderType">
          Новый заказ {{ newOrderType === 1 ? `ТС` : `грузчика` }}
        </h1>
      </div>
      <div class="order__mobile--data">
        <div class="order__mobile--block">
          <label class="date__picker--label">Дата подачи</label>
          <date-picker
            v-model="createDate"
            :shortcuts="shortcuts"
            type="date"
            valueType="timestamp"
            :disabled-date="disabledBeforeToday"
            format="DD-MM-YYYY"
            placeholder="дд:мм:гггг"
            required
          ></date-picker>
        </div>
        <div class="order__mobile--block">
          <form-input
            v-model="createTime"
            styles="min-width: 95%; text-align: left; width: 100%"
            type="time"
            id="createTime"
            label="Время подачи"
          ></form-input>
          <img
            class="input__custom--icon"
            for="createTime"
            src="@/assets/images/modal-order/time.svg"
            alt="Время подачи"
          />
        </div>
        <div class="order__mobile--block">
          <form-input
            v-model="requestNumber"
            label="Номер заявки"
            placeholder="Введите Ваш номер заявки"
            
            
          ></form-input>
        </div>
        <div v-if="newOrderType === 1" class="order__mobile--block">
          <form-select
            label="Тоннаж"
            v-model="tonnage"
            @select="changeTonnage"
            :options="tonnageArray"
            required
          ></form-select>
        </div>
        <div v-if="newOrderType === 1" class="order__mobile--block">
          <form-select
            label="Тип ТС"
            v-model="boxType"
            @select="changeBoxType"
            :options="boxTypeArray"
            required
          ></form-select>
        </div>
        <div class="order__mobile--block">
          <form-textarea
            label="Коментарий к заказу"
            v-model="commentOrder"
            @textarea="changeOrderComment"
            placeholder="Текст комментария"
            width="170"
          ></form-textarea>
        </div>
      </div>
      <div class="order__mobile--footer">
        <form-button
          :disabled="!checkReqyiredFields"
          title="Создать заказ"
          class="button__yellow"
          type="button"
          @click="saveOrder"
        ></form-button>
        <!-- <form-button
          title="Отменить"
          class="button__red"
          type="button"
          @click="closeOrder"
        ></form-button> -->
      </div>
    </div>
  </section>
</template>

<script>
// Store
import { mapActions, mapState } from "vuex";
// Mixins
import orderFunctions from "@/mixins/order-functions";

export default {
  name: "AddOrderModal",
  mixins: [orderFunctions],
  props: {
    addOrderModal: { type: Boolean, default: false },
    newOrderType: { type: Number, default: null }
  },
  data() {
    return {
      tonnage: null,
      boxType: null,
      commentOrder: "",
      successServer: false,
      orderId: null,
      requestNumber: "",
    };
  },
  computed: {
    // Store
    ...mapState(["token"])
  },
  methods: {
    // Store
    ...mapActions(["REFRESH_TOKEN_HANDLER", "ADD_ORDER"]),
    // Отправляем заказ на сервер
    async sendToServer(data) {
      await this.axios
        .post("/api/client/order/create", data, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          // console.log(response.data.response);
          this.orderId = response.data.response.orderId;
          this.ADD_ORDER(response.data.response).then(() => {
            this.$emit(`changeLoader`, false);
            this.successServer = true;
          });
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.sendToServer();
            });
          } else {
            console.error("Ошибка в получении sendToServer" + e.message);
          }
        });
    },
    // Создать заказ
    saveOrder() {
      this.$emit(`changeLoader`, true);
      const order = {
        createDate: this.createDateToServer,
        createTime: this.createTime,
        tonnage: this.tonnage,
        boxType: this.boxType,
        orderType: this.newOrderType,
        note: this.commentOrder,
        requestNumber: this.requestNumber,
        routeArray: [
          {
            address: "Адрес загрузки уточнить у клиента",
            arrivalDate: null,
            arrivalTime: null,
            comment: null,
            departedDate: null,
            departedTime: null,
            type: "Загрузка",
            open: false,
            position: 0
          }
        ]
      };
      // console.log("SAVE_ORDER", order);
      this.sendToServer(JSON.stringify(order));
    },
    // Закрываем окно
    closeOrder() {
      this.$emit("closeOrderHandler", false);
      this.createDate = null;
      this.createTime = null;
      this.tonnage = null;
      this.boxType = null;
      this.requestNumber='';
      this.commentOrder = "";
    },
    returnHandler() {
      this.closeOrder();
      this.successServer = false;
    }
  }
};
</script>
<style lang="scss">
.full__screen {
  z-index: 9999;
}

.success__server {
  @include flex;
  background: #27bb88;
  bottom: 0;
  color: $color-white;
  font-size: 1.4rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  div {
    text-align: center;
    width: 90%;
  }

  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  button {
    font-size: 1rem;
    margin: 2rem 0;
  }
}

.order__mobile {
  background: $color-white;
  overflow-y: auto;

  &--close {
    @include flex;
    cursor: pointer;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
    z-index: 16;

    &:hover {
      @include close-image-hover;
    }
  }

  &--header {
    @include flex;
    @include flex-content-start;
    background: $color-white;
    border: 1px solid $color-grey-light;
    color: $color-basic;
    height: 3rem;
    left: 0;
    position: absolute;
    right: 0;
    text-align: left;
    z-index: 15;

    h1 {
      font-size: 1.4rem;
      font-weight: bold;
      margin-left: 1rem;
    }
  }

  &--data {
    margin: 5rem auto 2rem auto;
    width: 95%;
  }

  &--block {
    height: 3rem;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;

    .date__picker--label,
    .form-input label,
    .form-select label,
    .form-textarea label {
      font-size: 0.9rem;
    }

    .form-input input[type="time"],
    .form-select,
    .form-select select,
    .mx-datepicker,
    .mx-input,
    .mx-input:hover,
    .mx-input:focus {
      height: 3rem !important;
      font-size: 1.2rem;
      width: 100% !important;
    }

    .form-textarea textarea {
      font-size: 1.2rem;
      height: 7rem;
      margin-bottom: 5rem;
      padding-top: 1rem;
      width: 100% !important;
    }

    // .form-select select option {
    //   overflow: hidden !important;
    //   white-space: nowrap !important;
    // }

    .mx-datepicker svg {
      height: 1.2rem;
      width: 1.2rem;
    }

    .input__custom--icon {
      height: 1.8rem;
      top: 0.6rem;
      right: 0.2rem;
      width: 1.8rem;
    }

    .form-select:after {
      @include image-arrow;
      background-size: 100% 100%;
      height: 1.3rem;
      top: 0.8rem;
      width: 1.3rem;
    }
  }

  &--footer {
    @include flex;
    background: $color-white;
    // border-top: 1px solid $color-grey-light;
    bottom: 0;
    height: 4rem;
    left: 0;
    position: fixed;
    right: 0;

    button {
      border-radius: 0 !important;
      font-size: 1.5rem;
      // margin: 0 1rem;
      height: 4rem !important;
      width: 100%;
    }
  }
}
</style>
